import classNames from "classnames";
import CardListBlock, {
  CardListBlockProps,
} from "components/blocks/CardListBlock/CardListBlock";
import ContactBlock, {
  ContactBlockProps,
} from "components/blocks/ContactBlock/ContactBlock";
import HighlightedInfoBlock, {
  HighlightedInfoBlockProps,
} from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";
import TilskuddPageHero, {
  TilskuddPageHeroBlockProps,
} from "components/blocks/TilskuddPageHeroBlock/TilskuddPageHeroBlock";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import { BlockContainer, ContentAnchors, MultiColumn } from "SiteComponents";
import styles from "./TilskuddPage.module.scss";
import { GridOverrideContext } from "src/utils/GridOverrideContext";

export interface TilskuddPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: TilskuddPageHeroBlockProps;
  highlightedInfo?: HighlightedInfoBlockProps;
  contactBlock?: ContactBlockProps;
  relatedTilskudd?: CardListBlockProps;
  relatedArticles?: CardListBlockProps;
}

const TilskuddPage: React.FC<TilskuddPageProps> = ({
  contentArea,
  hero,
  contentAnchors,
  mainIntro,
  highlightedInfo,
  contactBlock,
  relatedArticles,
  relatedTilskudd,
}) => {
  return (
    <main className={styles.tilskuddPage}>
      {hero && <TilskuddPageHero {...hero} />}

      <MultiColumn>
        {(mainIntro || highlightedInfo) && (
          <MultiColumn.Left
            gridStyle={{ lgOffset: 2, lg: 6 }}
            nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
          >
            <TopPageBlocks
              mainIntro={mainIntro}
              highlightedInfo={highlightedInfo}
              contentAnchors={contentAnchors}
            />
          </MultiColumn.Left>
        )}

        <MultiColumn.Right>
          <BlockContainer horizontalSpacing="md">
            {contactBlock && <ContactBlock {...contactBlock} />}
          </BlockContainer>
        </MultiColumn.Right>

        <MultiColumn.Left>
          {contentArea && <ContentArea {...contentArea} />}
        </MultiColumn.Left>
      </MultiColumn>

      {(relatedArticles || relatedTilskudd) && (
        <BlockContainer rowGap="xl" fullWidth>
          {relatedTilskudd && <CardListBlock {...relatedTilskudd} />}
          {relatedArticles && <CardListBlock {...relatedArticles} />}
        </BlockContainer>
      )}
    </main>
  );
};

export default TilskuddPage;
