import classNames from "classnames";
import styles from "./Image.module.scss";
interface Sizes {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
export interface ImageProps {
  src: string;
  alt: string;
  ratio?: "standard" | "medium" | "desktop" | "hero";
  hotspot?: {
    x: number;
    y: number;
  };
  className?: string;
  sizes?: Sizes;
}

function getRatioValue (ratio: string) {
  if(ratio === "medium"){
    return 4/3;
  }
  if(ratio === "desktop"){
    return 1200/800;
  }
  if(ratio === "hero"){
    return 16/9;
  }
  return 3/2;
}

const defaultSizes: Sizes = {
  sm: 280,
  md: 440,
  lg: 768,
  xl: 1024,
};

const Image: React.FC<ImageProps> = ({
  ratio = "standard",
  src,
  alt,
  hotspot,
  className,
  sizes = defaultSizes,
}) => {
  const ratioType = styles[ratio];

  const addHotSpot = hotspot ? `&rxy=${hotspot.x},${hotspot.y}` : "";

  const { sm, md, lg, xl } = sizes;
  let ratioValue = getRatioValue(ratio);
  const srcFunc = (size: number | undefined) =>
    size ? `${src}?width=${size}&height=${(size || 0)/ratioValue}&${addHotSpot} ${size}w,` : "";
  const srcSet = `
    ${srcFunc(sm)}
    ${srcFunc(md)}
    ${srcFunc(lg)}
    ${srcFunc(xl)}
  `;

  const sizesAttribute = `
    (max-width: 400px) ${sm}px
    (max-width: 480px) ${md}px
    (max-width: 768px) ${lg}px
    (max-width: 1024px) ${xl}px
    ${xl}px
  `.trim();

  const height = sizes?.lg === null ? "" : `&height=${(sizes.lg || 0)/ratioValue}`;
  const srcWithQueries = `${src}?width=${sizes.lg}${height}${addHotSpot}`;
  return (
    <img
      className={classNames(styles.image, ratioType, className)}
      src={srcWithQueries}
      alt={alt}
      srcSet={srcSet}
      sizes={sizesAttribute}
    />
  );
};

export default Image;
